<template>
  <div>
    <div class="pb-6">
      <ProductDescriptionComponent
        :readonly="$route.name.includes('readonly')"
        :items="cartSavedItems"
        @removeCartItem="removeCartItem"
        @removeCartItemPosition="removeCartItemPosition"
        @decreaseCartItemCount="decreaseCartItemCount"
        @increaseCartItemCount="increaseCartItemCount"
        :reorder="cartSavedItems.length > 0"
        class="mb-6"
      />
      <v-container class="pa-0">
        <v-row class="base_button" no-gutters>
          <v-col class="space"></v-col>
          <!-- <v-col cols="4"> -->
          <div class="col-lg-4 col-sm-8">
            <BaseButton
              class="align-self-end"
              :title="$t('PRODUCT_DESCRIPTION.go_to_order')"
              @click="
                $router.push({
                  name: 'admin order registration',
                  query: {
                    items: cartSavedItems.length ? cartSavedItems : cartItems,
                  },
                })
              "
            />
          </div>
          <!-- </v-col> -->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import BaseButton from './../layout/BaseButton.vue'
import ProductDescriptionComponent from './../content/ProductDescriptionComponent.vue'
const { mapActions: Actions_orderHistory } = createNamespacedHelpers(
  'orderHistory'
)
const {
  mapActions: Actions_brand_doubt,
  mapState: State_brand_doubt,
} = createNamespacedHelpers('brandDoubt')
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('cart')
export default {
  name: 'ProductDescription',
  components: {
    BaseButton,
    ProductDescriptionComponent,
  },
  data() {
    return {
      cartSavedItems: [],
      fullPrice: 0,
      baseUrl: '',
    }
  },
  computed: {
    ...mapState(['cartItems']),
    ...State_brand_doubt(['orderDetails']),
  },
  methods: {
    ...Actions_orderHistory(['fetchOrderById']),
    ...mapMutations(['SET_CART_ITEMS']),
    ...mapActions([
      'removeCartItem',
      'removeCartItemPosition',
      'increaseCartItemCount',
      'decreaseCartItemCount',
    ]),
    ...Actions_brand_doubt([
      // 'saveBrandCount',
      'fetchOrderDetailsById',
      'fetchOrderDetails',
    ]),
  },
  async created() {
    this.baseUrl = this.$store.state.auth.API_URL
    this.fetchOrderDetails()
    if (this.$route.params.id) {
      this.SET_CART_ITEMS([])
      localStorage.setItem('bought_brands', JSON.stringify([]))
      const data = await this.fetchOrderById(this.$route.params.id)
      let editedData = {
        soles: [],
        colors: [],
        sizes: [],
        name: data?.order_items[0]?.reyestr?.name,
        photo: data?.order_items[0]?.reyestr?.photo,
        reyestrItem: data?.order_items[0]?.reyestr,
        id: data?.order_items[0]?.reyestr?.id,
        logo: { logo: data?.logo },
        from: data?.from,
        price_type: data?.order_items[0]?.sole ? 'sole' : 'reyestr',
      }
      const colors = [
        ...new Set(
          data?.order_items?.map((item) => {
            return {
              name: item.color,
              soleId: item.sole_id,
            }
          })
        ),
      ].filter(
        (obj, index, arr) =>
          arr.findIndex((item) => item.name === obj.name) === index
      )
      // editedData.selected_brands = selected_brands.filter(
      //   (obj, index, arr) =>
      //     arr.findIndex((item) => item.id === obj.id) === index
      // )

      editedData.sizes = data?.order_items?.map((item) => {
        const color_id = data?.order_items[0]?.reyestr?.colors.find(
          (el) => el.name === item.color
        )
        return {
          name: item.size,
          colorId: color_id.id,
          count: item.count,
          price: item.price,
          soleId: item.sole_id,
        }
      })
      colors?.forEach((item) => {
        data.order_items[0]?.reyestr?.colors.forEach((color) => {
          if (color.name === item.name) {
            editedData.colors = [
              ...editedData.colors,
              { ...color, soleId: item.soleId },
            ]
          }
        })
      })
      let selected_brands
      selected_brands = data?.order_items?.map((item) => {
        return {
          ...item.selected_sole_brand,
          soleId: item.sole_id,
        }
      })
      selected_brands.forEach((item) => {
        const parts = item?.price?.split('/')
        item.selected_count = data?.order_items.reduce((acc, curr) => {
          if (curr.sole_id === item.soleId && curr.sole_brand_id === item.id) {
            return (acc += curr.count)
          } else {
            return acc
          }
        }, 0)
        item.brandsPrice = +parts[0]
        item.brandPrice = +parts[1]
        item.reyestrId = data?.order_items[0]?.reyestr?.id
      })
      selected_brands.forEach((item) => {
        const existingBrand = this.orderDetails.find(
          (el) => el.brand_id === item.id
        )

        item.boughtBrandsKit =
          item.selected_count >
          existingBrand?.current_employee_branding?.curren_amount
            ? Math.ceil(
                (item.selected_count -
                  existingBrand?.current_employee_branding?.curren_amount) /
                  +item.count
              )
            : 0
        item.isEnough =
          item.selected_count >
          existingBrand?.current_employee_branding?.curren_amount
            ? 'not enough'
            : 'enough'
      })
      if (editedData.price_type === 'sole') {
        selected_brands = selected_brands.filter(
          (obj, index, arr) =>
            arr.findIndex((item) => item.soleId === obj.soleId) === index
        )

        editedData.soles = data?.order_items?.map((item) => {
          return {
            ...item.sole,
            selected_brands: [],
          }
        })

        editedData.soles = editedData.soles.map((el) => {
          return {
            ...el,
            selected_brands: selected_brands.filter(
              (item) => item.soleId === el.id
            ),
          }
        })
      }

      editedData.soles = editedData.soles.filter(
        (obj, index, arr) =>
          arr.findIndex((item) => item.id === obj.id) === index
      )
      this.cartSavedItems.push(editedData)
      this.cartSavedItems = [...this.cartSavedItems]
      let brands = []
      selected_brands.forEach((brand) => {
        const existingBrand = this.orderDetails?.find(
          (el) => el.brand_id === brand.id
        )
        if (existingBrand) {
          const isAdded = brands.find((item) => item.id === brand.id)
          if (!isAdded) {
            if (
              brand?.selected_count <=
              existingBrand.current_employee_branding.curren_amount
            ) {
              brands.push({
                ...brand,
                remainder:
                  existingBrand.current_employee_branding.curren_amount -
                  brand?.selected_count,
                selected_count: brand?.selected_count,
                starter_remainder:
                  existingBrand.current_employee_branding.curren_amount,
              })
            } else {
              brands.push({
                ...brand,
                remainder:
                  existingBrand.current_employee_branding.curren_amount >
                  brand.selected_count
                    ? existingBrand.current_employee_branding.curren_amount -
                      +brand?.selected_count
                    : Math.ceil(
                        (brand.selected_count -
                          existingBrand.current_employee_branding
                            .curren_amount) /
                          +brand.count
                      ) *
                        +brand.count +
                      existingBrand.current_employee_branding.curren_amount -
                      brand.selected_count,
                selected_count: +brand?.selected_count,
                boughtBrandsKit:
                  +brand.count > brand.selected_count
                    ? 0
                    : brand.selected_count -
                      existingBrand.current_employee_branding.curren_amount,
                starter_remainder:
                  existingBrand.current_employee_branding.curren_amount,
              })
            }
          } else {
            brands = brands.map((item) => {
              if (item.id === brand.id) {
                if (brand?.selected_count <= item.remainder) {
                  return {
                    ...brand,
                    remainder: item.remainder - brand?.selected_count,
                    selected_count: brand?.selected_count + item.selected_count,
                  }
                } else {
                  return {
                    ...brand,
                    remainder:
                      item.remainder > brand.selected_count
                        ? item.remainder - +brand?.selected_count
                        : Math.ceil(
                            (brand.selected_count - item.remainder) /
                              +brand.count
                          ) *
                            +brand.count -
                          brand.selected_count,
                    selected_count:
                      +brand?.selected_count + item.selected_count,
                    boughtBrandsKit:
                      +brand.remainder > brand.selected_count
                        ? 0
                        : Math.ceil(
                            (brand.selected_count - item.remainder) /
                              +brand.count
                          ),
                  }
                }
              } else {
                return item
              }
            })
          }
        }
      })
      localStorage.setItem('bought_brands', JSON.stringify(brands))
    } else {
      this.cartSavedItems = []
    }
  },
}
</script>

<style scoped lang="scss">
$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;

@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}
.space {
  display: block;
  @media (max-width: 575px) {
    display: none;
  }
}

.base_button {
  display: flex;
  justify-content: center !important;
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.p_description {
  background: #ffffff;

  .d_border {
    border: 1px solid #f5f5f5;
    height: auto;
    padding-bottom: 20px;
    border-radius: 1px;

    @media (max-width: 668px) {
      margin: 0.5rem;
    }

    .d_title {
      @include ftz(0.875rem, 500, $bl);
      text-transform: uppercase;
      height: 2rem;
      background: #fbfbfb;
      padding: 5px 25px;

      .col-sm-4 {
        @media (max-width: 481px) {
          width: 25%;
        }
      }
      .col-sm-1 {
        @media (max-width: 481px) {
          width: 12%;
        }
      }
      .col-sm-2 {
        @media (max-width: 481px) {
          width: 17%;
        }
      }
    }

    .d_line_bottom {
      border-bottom: 1px solid #f5f5f5;
      margin: 1px 20px;
      @media (max-width: 668px) {
        margin: 1px 2px;
      }

      .col-sm-1 {
        @media (max-width: 481px) {
          width: 20%;
        }
      }
      .col-sm-11 {
        @media (max-width: 481px) {
          width: 80%;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
    .d_img {
      width: 136px;
      height: 132px;
      border-radius: 2px;
    }

    .d_product {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      .d_p_title {
        @include ftz(1.25rem, 700, $bl);
      }
    }

    .order_data {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      padding: 1rem 0;
      & > div {
        padding-top: 0;
        padding-bottom: 0;
      }

      @media (max-width: 481px) {
        overflow-x: scroll;
      }

      .col-sm-4 {
        @media (max-width: 481px) {
          width: 40%;
          padding: 0;
        }
      }
      .col-sm-1 {
        @media (max-width: 481px) {
          width: 15%;
          padding: 0;
        }
      }
      .col-sm-2 {
        @media (max-width: 481px) {
          width: 25%;
        }
      }
      .product_description {
        @include ftz(0.875rem, 400, #666666);
        width: 70%;
        padding-left: 0.5rem;

        @media (max-width: 769px) {
          padding-left: 0;
          @include ftz(0.75rem, 400, #666666);

          @media (max-width: 481px) {
            width: 95%;
          }
        }
      }

      input {
        border: 1px solid #f3efe3;
      }
      .d_color {
        background: #f3efe3;
        border-radius: 1px;
        width: 41px;
        height: 39px;
      }

      .d_size {
        @include ftz(1.25rem, 400, #878787);
        border: 1px solid #f2f2f2;
        border-radius: 1px;
        padding: 10px;
        font-family: 'Roboto Condensed';

        @media (max-width: 668px) {
          padding: 10px 10px 5px;
        }
      }

      .d_price {
        @include ftz(1.125rem, 500, $bl);

        @media (max-width: 668px) {
          @include ftz(1rem, 500, $bl);
        }
      }

      .btn-quantity {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        .btn-number {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .b_minus,
        .b_plus {
          width: 33px;
          height: 31px;
          background: #f8f8f8;
          border-radius: 1px;
        }
        .b_plus {
          background: #cfebcc;
        }

        .form-control {
          border: 1px solid #f2f2f2;
          width: 58px;
          height: 31px;
          font-family: 'Roboto Condensed';
          text-align: center;

          @media (max-width: 668px) {
            width: 40px;
            padding: 2px;
          }
        }
      }

      .d_sum {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 896px) {
          padding-right: 0;
        }
        .total_sum {
          @include ftz(1.125rem, 500, $bl);

          @media (max-width: 896px) {
            margin-left: 1.5rem;

            @media (max-width: 668px) {
              @include ftz(1rem, 500, $bl);
            }
          }
        }
      }
    }
  }
  .payment_details {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 15px;

    @media (max-width: 769px) {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
    }

    .col-sm-3 {
      @media (max-width: 769px) {
        width: 77%;
      }
    }

    .agreement_data {
      display: block;
      margin-bottom: 1rem;

      .payment_terminal {
        @include ftz(1rem, 700, #31343c);
        letter-spacing: 0.01em;
      }

      .payment_info {
        @include ftz(0.75rem, 400, #666666);
        letter-spacing: 0.01em;
        padding: 5px 0 15px 0;
      }
    }
  }

  .total_order {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 481px) {
      display: block;
    }
    .btn_item {
      .btn_total {
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid #f2f2f2;
        border-radius: 2px;
        text-transform: uppercase;
        @include ftz(1rem, 500, #666666);
        background: #ffffff;
        padding: 20px;

        @media (max-width: 668px) {
          @include ftz(0.7rem, 500, #666666);
          padding-top: 18px;
          padding-bottom: 18px;
          @media (max-width: 481px) {
            @include ftz(0.6rem, 500, #666666);
          }
        }

        .btn_product_sum {
          @include ftz(1.5rem, 400, #666666);
          font-family: 'Roboto Condensed';
          padding-left: 15px;

          @media (max-width: 769px) {
            @include ftz(1rem, 400, #666666);

            @media (max-width: 668px) {
              @include ftz(0.75rem, 400, #666666);

              @media (max-width: 481px) {
                @include ftz(0.65rem, 400, #666666);
              }
            }
          }
        }

        .t_order_total {
          @include ftz(1rem, 400, #666666);
        }
      }

      &:nth-child(5) {
        margin-right: 0;
      }
      &:not(:last-child) {
        @media (max-width: 481px) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.customization_body {
  width: 100%;
  height: 1119px;
  position: absolute;
  background: rgba(0, 0, 0, 0.47);
  display: none;
  justify-content: space-around;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;

  .customization {
    width: 514px;
    height: 524px;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    background: $white;
    justify-content: space-around;

    .customization_title {
      @include ftz(1.5rem, 500, $bl);
      text-transform: capitalize;
    }

    .customization-item {
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-left: 5rem;
      margin-bottom: 2rem;

      .item_t {
        padding-left: 1rem;
        display: block;
        .item_editor {
          @include ftz(1.25rem, 700, $bl);
          text-transform: capitalize;
          margin: 0;
        }
        .item_conditions {
          @include ftz(0.75rem, 500, $bl);
        }
      }
    }

    .customization_replace {
      width: 351px;
      height: 46px;
      background: #cfebcc;
      @include ftz(0.875rem, 500, #666666);
      letter-spacing: 0.13em;
      text-transform: uppercase;
    }
  }
}
</style>
