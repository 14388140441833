<template>
  <div class="col-sm-12 customization w-100">
    <span>Брендирование</span>
    <div class="row mx-n2 mt-5">
      <div class="col-sm-4 px-2">
        <div class="customization__radio py-1">
          <div class="pa-3">
            <div class="customization__radio-btn">
              <input
                v-model="enableLogo"
                :value="0"
                type="radio"
                name="enable_logo"
              />
              <span class="checkmark"></span>
            </div>
          </div>
          <div class="customization__radio-text text-truncate">
            <span>Оставить</span>
            Стоимость не меняется
          </div>
        </div>
      </div>
      <div class="col-sm-4 px-2">
        <div class="customization__radio py-1">
          <div class="pa-3">
            <div class="customization__radio-btn">
              <input
                v-model="enableLogo"
                :value="1"
                type="radio"
                name="enable_logo"
              />
              <span class="checkmark"></span>
            </div>
          </div>
          <div class="customization__radio-text text-truncate">
            <span>Заменить</span>
            Стоимость будет меняться в зависимости от логотипа
          </div>
        </div>
      </div>
      <div class="col-sm-4 px-2">
        <div class="customization__radio py-1">
          <div class="pa-3">
            <div class="customization__radio-btn">
              <input
                v-model="enableLogo"
                :value="2"
                type="radio"
                name="enable_logo"
              />
              <span class="checkmark"></span>
            </div>
          </div>
          <div class="customization__radio-text text-truncate">
            <span>Удалить</span>
            Стоимость не меняется
          </div>
        </div>
      </div>
    </div>
    <div v-show="enableLogo < 2" class="row mx-n2">
      <div
        class="col-sm-4 customization__position-item pa-2"
        v-for="(item, index) in brands"
        :key="index"
      >
        <div class="position-item__ratio">
          <div
            :style="
              `background: url('${baseUrl}${item.photo}') center / cover no-repeat`
            "
          >
            <!-- v-model="selected_brands" -->
            <v-checkbox
              :input-value="selected_brands"
              @change="onPositionChange"
              :value="item.id"
              style="position: absolute; bottom: 0; right: 0"
              hide-details
              dense
              class="ma-0 pa-0"
            ></v-checkbox>
            <!-- @change="$emit('onBrandPositionChange', $event.target.value)" -->
            <!-- v-model="logo.positions" -->
          </div>
        </div>
      </div>
    </div>
    <div v-show="enableLogo === 1" class="row justify-end ma-0 mt-5">
      <div class="pa-3 col-sm-7" style="border: 1px solid #f1f1f1">
        <img
          v-if="getPreview(logo)"
          style="width: 100%; height: 100%"
          :src="getPreview(logo)"
          class="pa-0"
        />
      </div>
      <div class="col-sm-5 position-relative">
        <label
          for="file"
          class="upload-logo__btn cursor-pointer ma-0"
          style="z-index: 1"
        >
          Загрузить свой логотип
        </label>
        <v-file-input
          id="file"
          class="position-absolute ma-0 pa-0"
          style="opacity: 0; inset: 12px; z-index: -1"
          @change="onLogoChange"
        ></v-file-input>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('brandings')
export default {
  props: {
    logo: {
      type: Object,
      default: () => {},
    },
    brands: {
      type: Array,
      default: () => [],
    },
    selected_brands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      enableLogo: 0,
      customizationEnabled: true,
      selectedLogoPositions: [],
      brandings: [],
      baseUrl: '',

      emir: [1],
    }
  },
  computed: {
    logoPositions() {
      const imgs = [
        '/img/img_logo-customization/position-1.webp',
        '/img/img_logo-customization/position-2.webp',
        '/img/img_logo-customization/position-3.webp',
        '/img/img_logo-customization/position-4.webp',
        '/img/img_logo-customization/position-5.webp',
      ]
      return imgs
    },
  },
  watch: {
    enableLogo(val) {
      if (val !== 0) {
        this.customizationEnabled = false
      } else {
        this.customizationEnabled = true
      }
    },
  },
  methods: {
    ...mapActions(['fetchBrandingById']),
    onLogoChange(e) {
      this.$emit('onLogoChange', e)
    },
    onPositionChange(e) {
      this.$emit('onPositionChange', e)
    },
    getPreview(image) {
      if (image && image.logo) {
        return URL.createObjectURL(image.logo)
      }
      return ''
    },
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
  },
}
</script>

<style scoped lang="scss">
$gr_col: #818181;
@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}
.upload-logo__btn {
  text-align: center;
  width: 100%;
  height: fit-content;
  padding: 10px 0;
  font-size: 12px;
  font-weight: 700;
  color: #666666;
  line-height: 18px;
  border-radius: 2px;
  text-transform: uppercase;
  font-family: 'Alegreya Sans';
  background-color: #cfebcc;
  letter-spacing: 0.13em;
  &-disabled {
    background-color: #eee;
    color: #939393;
    cursor: default;
  }
}
.customization {
  background-color: #fff;
  & > span {
    @include ftz(17px, 800, #000);
  }
  &__radio {
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    &-text {
      display: block;
      font-size: 12px;
      line-height: 15px;
      span {
        font-size: 16px;
        display: block;
        font-weight: 700;
      }
    }
    &-btn {
      position: relative;
      font-size: 22px;
      user-select: none;
      height: 19px;
      width: 19px;

      input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 19px;
        width: 19px;
        border-radius: 50%;
        border: 1px solid #d8d7d8;
      }
      input:checked ~ .checkmark {
        border-color: #b8deb4;
      }

      .checkmark:after {
        width: 9px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        height: 9px;
        border-radius: 50%;
        background-color: #b8deb4;
      }
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
    }
  }
  .position-item {
    &__ratio {
      padding-top: 80%;
      position: relative;
      & > div {
        position: absolute;
        inset: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .ratio__checkbox {
        display: block;
        cursor: pointer;
        font-size: 22px;
        user-select: none;
        // width: 15px;
        // height: 15px;
        position: absolute;
        bottom: 5px;
        right: 5px;
        margin: 0;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        input:checked ~ .checkmark {
          background-color: #002b4e;
        }

        .checkmark:after {
          left: 4px;
          top: 2px;
          width: 5px;
          height: 8px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
        }

        input:checked ~ .checkmark:after {
          display: block;
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #fff;
        border: 1px black solid;
        border-radius: 2px;
      }

      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }
    }
  }
}
</style>
